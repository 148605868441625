import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import "@fontsource/roboto-slab";
import "@fontsource/barlow";
import CountryProvider from "./src/store/country/CountryContext";
import LanguageProvider from "./src/store/language/LanguageContext";

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || "/", { replace: true });
};

export const wrapRootElement = ({ element }) => {
  return (
    <LanguageProvider>
      <CountryProvider>
        <Auth0Provider
          domain={process.env.AUTH0_DOMAIN ?? "dev-a-5f3c07.us.auth0.com"}
          clientId={
            process.env.AUTH0_CLIENTID ?? "ouq607MTLb5NGEVxRjgstsaYsYlB5qOw"
          }
          authorizationParams={{ redirect_uri: window.location.origin }}
          onRedirectCallback={onRedirectCallback}
          defaultSrc={["'self'"]}
          objectSrc={["'none'"]}
          scriptSrc={["'self'", "unpkg.com", "polyfill.io"]}
          styleSrc={["'self'", "https: 'unsafe-inline'"]}
          connectSrc={["'self'", "xxxxxx.eu.auth0.com"]}
          upgradeInsecureRequests={[]}
        >
          {element}
        </Auth0Provider>
      </CountryProvider>
    </LanguageProvider>
  );
};
