import * as hu from "./hu.json";
import * as en from "./en.json";
import * as ger from "./ger.json";

const languages = {
  hu,
  en,
  ger,
};

export default languages;
