import React, { createContext, useEffect, useState } from "react";
import languages from "../../constants/dictionary";

export const LanguageContext = createContext({
  selectedLanguage: "hu",
  setSelectedLanguage: () => {},
  dictionary: languages.hu,
});

const LanguageProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem("swissJobsLang");

  const [selectedLanguage, setSelectedLanguage] = useState(
    storedLanguage ?? "hu"
  );
  const [dictionary, setDictionary] = useState(languages.hu);
  // const [dictionaryOn, setDictionaryOn] = useState(true);
  const dictionaryOn = true;

  const sections = {
    hu: ["#regisztracio", "#orszagok", "#gyik", "#kapcsolat", "#uzenetkuldes"],
    en: ["#registration", "#countries", "#faq_en", "#contact", "#contact-form"],
    ger: ["#anmeldung", "#lander", "#faq_de", "#kontakt", "#kontakt-form"],
  };

  /*
  useEffect(() => {
    if (window.location.search.indexOf("dictionaryOn") >= 0) {
      setDictionaryOn(true);
    } else {
      setDictionaryOn(false);
    }
  }, []);
  */

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      if (sections.hu.includes(hash)) {
        setSelectedLanguage("hu");
      } else if (sections.en.includes(hash)) {
        setSelectedLanguage("en");
      } else if (sections.ger.includes(hash)) {
        setSelectedLanguage("ger");
      }
      const newHash = hash.replace("#", "");

      setTimeout(() => {
        const section = document.getElementById(newHash);

        section?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    /*
    if (dictionaryOn) {
      setDictionary(languages[selectedLanguage]);
      localStorage.setItem('swissJobsLang', selectedLanguage)
    } else {
      setDictionary(languages.hu);
    }*/
    setDictionary(languages[selectedLanguage]);
    localStorage.setItem("swissJobsLang", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        dictionary,
        dictionaryOn,
        selectedLanguage,
        setSelectedLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
