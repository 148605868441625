import React, { createContext, useState } from "react";

export const CountryContext = createContext({
  selectedCountry: "Svájc",
  setSelectedCountry: () => {},
});

const CountryProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState("Svájc");

  return (
    <CountryContext.Provider value={{ selectedCountry, setSelectedCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
